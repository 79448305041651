import React from "react";
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar=()=>
{
    return(<>
            <div className="container-fluid">
            <div className="row">
                <div className="navbar navbar-expand-md bg-primary justify-content-end">
                    <button class="navbar-toggler text-white mx-2" data-bs-target="#xyz" data-bs-toggle="collapse">
                    <span class="navbar-toggler-icon"></span></button>
                    <div class="collapse navbar-collapse" id="xyz">
                        <ul class="navbar-nav p-3">
                            <li className="nav-item"><Link className="nav-link text-white" to="/List">Student List</Link></li>
                            <li className="nav-item"><Link className="nav-link text-white" to="/View">Student View</Link></li>
                            <li className="nav-item"><Link className="nav-link text-white" to="/Add">Student Add</Link></li>
                            <li className="nav-item"><Link className="nav-link text-white" to="/Edit">Student Edit</Link></li>
                            <li className="nav-item"><Link className="nav-link text-white" to="/Import">Import List</Link></li>
                            <li className="nav-item"><Link className="nav-link text-white" to="/Export">Export List</Link></li>
                        </ul>
                    </div>
                        
                </div>
            </div>
            </div>
            </>)
}
export default Navbar;
