import React from 'react';
import './Routess.css';

import {Routes,Route} from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import List from '../List/List';
import View from '../View/View';
import Add from '../Add/Add';
import Edit from '../Edit/Edit';
import Import from '../Import/Import';
import Export from '../Export/Export';
import Error from '../Error/Error';

const Routess=()=>{
    return(<>
        <Routes>
            <Route path="/" element={<Dashboard/>}/>
            <Route path="/List" element={<List/>}/>
            <Route path="/View" element={<View/>}/>
            <Route path="/Add" element={<Add/>}/>
            <Route path="/Edit" element={<Edit/>}/>
            <Route path="/Import" element={<Import/>}/>
            <Route path="/Export" element={<Export/>}/>
            <Route path="*" element={<Error/>}/>
        </Routes>
    </>)
}

export default Routess;

