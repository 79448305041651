//import logo from './logo.svg';
//import './App.css';
import Navbar from './component/Navbar/Navbar';
import { BrowserRouter} from 'react-router-dom';
import Routess from './pages/Routess/Routess';
// Bootstrap 
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.js'

function App() {
  return(<>
  <BrowserRouter>
      <Navbar/>
      <Routess/>
  </BrowserRouter>
  </>);
}

export default App;
