import React from "react";
import './Dashboard.css';

const Dashboard=()=>{
    return(<>
        <div className="container-fluid">
           <div className="row">
                <h1 className="text-center">This is Dashboard page</h1>
           </div>
        </div>
       
    </>)
}

export default Dashboard;